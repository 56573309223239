import { Button } from 'antd'
import React, { FC } from 'react'
import { PublishBlock } from '../../simples/PublishBlock'
import styles from './ListItem.module.scss'

type Props = {
    name: string
    description: string
    photoPath?: string
    onPublish: () => void
    onUnPublish: () => void
    onChange: () => void
    onDelete: () => void
    isPublish: boolean
    withFeedback?: boolean
    verified?: boolean
    onFeedbackClick?: () => void
}

/**
 *
 * @param name
 * @param description
 * @param photoPath
 * @param onPublish
 * @param onUnPublish
 * @param onChange
 * @param onDelete
 * @param isPublish
 * @param withFeedback
 * @param onFeedbackClick
 * @returns
 */

const ListItem: FC<Props> = ({
    name,
    description,
    photoPath,
    onPublish,
    onUnPublish,
    onChange,
    onDelete,
    isPublish,
    withFeedback = false,
    onFeedbackClick,
    verified,
}) => {
    return (
        <div className={styles.holder}>
            <div className={styles.left}>
                <div className={styles.image}>
                    <img
                        alt=""
                        src={`${process.env.REACT_APP_API_URL}/${photoPath}`}
                    />
                </div>
                <div className={styles.textWrapper}>
                    <h3 className={styles.title}>{name}</h3>
                    <p className={styles.description}>{description}</p>
                </div>
            </div>
            <div className="">
                <PublishBlock
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    onChange={onChange}
                    onDelete={onDelete}
                    isPublish={isPublish}
                />
                {withFeedback && (
                    <Button onClick={onFeedbackClick} type={'ghost'}>
                        Отзывы
                    </Button>
                )}
            </div>

            {verified && <div className={styles.verified}>проверено</div>}
        </div>
    )
}

export default React.memo(ListItem)
